<template>
  <div v-loading="loading">
    <div v-if="tableData && tableData.length && !addOrEditingTables">
      <div class="mb-1" style="width: 100%">
        All Data
        <div class="action-links">
          <el-button
            type="danger"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="addNewRow('relationalTable')"
            >Relational Table</el-button
          >
          <el-button
            type="danger"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="addNewRow('dataTable')"
            >Data table Config</el-button
          >
        </div>
        <div style="margin-top: 20px">
          <el-collapse accordion>
            <el-collapse-item
              name="1"
              v-for="(item, index) in tableData"
              :key="index + '_collapse'"
              class="rule-collapse"
            >
              <template slot="title">
                <div style="width: 100%" class="ml-1">
                  {{ item.name }}
                  <div class="action-links">
                    <el-link
                      :underline="false"
                      type="primary"
                      class="mr-1"
                      @click.stop="editRule(index)"
                      ><i class="el-icon-edit"></i>
                    </el-link>
                    <el-link
                      type="danger"
                      :underline="false"
                      @click.stop="onDelete(index)"
                      ><i class="el-icon-delete"></i>
                    </el-link>
                  </div>
                </div>
              </template>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div style="margin-top: 20px" class="action-links">
          <el-button type="primary" plain size="mini" @click="saveAllTables()"
            >Save</el-button
          >
        </div>
      </div>
    </div>
    <div
      class="rule-container"
      v-loading="editLoading"
      :element-loading-text="loadingText"
      v-else-if="addOrEditingTables"
    >
      <!-- <template slot-scope="scope"> -->
      <el-row>
        <el-col :span="24">
          Table Name
          <el-input
            v-model="currentTable.name"
            placeholder="Table name"
            :disabled="checkIsDisabled()"
            class="mb-1"
            size="mini"
            style="width: 80%"
            @change="checkRuleName(currentTable.name)"
          >
          </el-input>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px" v-if="current_type == 'relationalTable'">
        <el-col :span="12">
          Select Entity
          <el-select
            v-model="currentTable.entity_id"
            placeholder="Select Entity"
            filterable
            @change="getEntityFilters"
            size="mini"
          >
            <el-option
              v-for="entity in allEntitiesData"
              :key="entity._id"
              :label="entity.name"
              :value="entity._id"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="12">
          Select Type
          <el-select
            v-model="currentTable.type"
            filterable
            size="mini"
            :disabled="!currentTable.entity_id"
            @change="handleTypeChange()"
          >
            <el-option
              v-for="(item, i) in tableTypes"
              :key="item.value + i"
              :label="item.label"
              :value="item.value"
              :disabled="isPdfOptionDisabled(item.value)"
              >{{ item.label }}</el-option
            >
          </el-select>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px" v-if="current_type == 'relationalTable'">
        <el-col :span="12">
          Select Filter
          <el-select
            v-loading="filterLoading"
            v-model="currentTable.filters"
            clearable
            size="mini"
          >
            <el-option
              v-for="filter in currentEntityFilters"
              :key="filter._id"
              :label="filter.filter_name"
              :value="filter._id"
              >{{ filter.filter_name }}</el-option
            >
          </el-select>
        </el-col>
        <el-col :span="12">
          Select Fields
          <el-select
            v-loading="filterLoading"
            v-model="currentTable.selectedFields"
            clearable
            filterable
            collapse-tags
            multiple
            :disabled="currentTable.type != 'email_body'"
            size="mini"
            :multiple-limit="12"
          >
            <el-option
              v-for="(field, i) in selectedEntityFields"
              :key="field._id + i"
              :label="field.label"
              :value="field.template_key"
              >{{ field.label }}</el-option
            >
          </el-select>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px" v-else>
        <el-col :span="12">
          Select Data Table
          <el-select
            v-model="currentTable.data_table_key"
            clearable
            @change="handleDataTableChange(currentTable.data_table_key)"
            size="mini"
          >
            <el-option
              v-for="field in dataTableFields"
              :key="field.template_key"
              :label="field.label"
              :value="field.template_key"
              >{{ field.label }}</el-option
            >
          </el-select>
        </el-col>
        <el-col :span="12">
          <div
            v-if="
              currentTable.data_table_key &&
              mentionableDataTableFields &&
              mentionableDataTableFields.length
            "
          >
            <el-button @click="configureDataTable" size="mini">
              configure a row
            </el-button>
            <p
              v-if="!currentTable.data_table_content"
              style="font-size: 10px; color: red; font-weight: 700"
            >
              Please Configure row
            </p>
          </div>
        </el-col>
      </el-row>
      <div class="action-footer">
        <el-button size="mini" @click="cancelNewRule">Cancel</el-button>
        <el-button
          type="primary"
          size="mini"
          :disabled="checkSaveDisabled()"
          @click="saveNewTable"
          >Add</el-button
        >
      </div>
      <!-- </template> -->
    </div>
    <div v-else-if="!tableData.length" class="empty-alert">
      <div>
        <img src="@/assets/img/nodata2.svg" />
      </div>
      <div>
        <el-button
          type="danger"
          icon="el-icon-plus"
          plain
          @click="addNewRow('relationalTable')"
          class="add-rule"
          size="mini"
          >Add Relational Table</el-button
        >
        <el-button
          type="danger"
          icon="el-icon-plus"
          plain
          @click="addNewRow('dataTable')"
          class="add-rule"
          size="mini"
          >Configure Data Table</el-button
        >
      </div>
    </div>
    <dialog-component
      :title="'Configure Single Row'"
      :visible="ckEditorDialog"
      :containerWidth="'50%'"
      @before-close="resetPopUp"
    >
      <div style="padding: 20px 10px">
        <ckeditor
          :config="editorConfig"
          v-model="currentTable.data_table_content"
          :key="editorKey"
        >
        </ckeditor>
      </div>
    </dialog-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EntityHelper from "@/mixins/EntitiesHelper";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
import { ckEditorConfig } from "@/assets/data/ckeditor_config.ts";

// import { bus } from "../../main";
export default {
  name: "EmailRelationalDataTableConfig",
  props: ["data", "allEntitiesData", "fields", "allCompanyDataFields"],
  mixins: [EntityHelper, TemplateBuilderHelper],
  async mounted() {
    this.getNeccessaryDetails();
  },
  data() {
    return {
      loading: false,
      current_type: "",
      fieldsObject: {},
      dataTableFields: [],
      editorConfig: JSON.parse(JSON.stringify(ckEditorConfig)),
      ckEditorDialog: false,
      mentionableDataTableFields: [],
      currentTable: {
        name: "",
        entity_id: "",
        filters: "",
        selectedFields: [],
        type: "email_body",
        data_table_key: "",
        data_table_content: "",
      },
      globalVariablesList: [],
      currentTableIndex: -1,
      addOrEditingTables: false,
      currentEntityFilters: [],
      selectedEntityFields: [],
      filterLoading: false,
      tableTypes: [
        {
          label: "Table in Email Body",
          value: "email_body",
        },
        {
          label: "Attachment as Excel",
          value: "attachment_as_excel",
        },
        {
          label: "Attachment as Pdf",
          value: "attachment_as_pdf",
        },
      ],
      editLoading: false,
      loadingText: "",
      tableData: [],
      selectedEntity: null,
      editorKey: 1,
    };
  },
  computed: {
    ...mapGetters("auth", [
      "getDefaultDateFormat",
      "getAuthenticatedUser",
      "getUserTypeList",
      "getUserType",
      "getCompanyUsers",
    ]),
    ...mapGetters("users", ["getUsersList"]),
    ...mapGetters("entities", ["getEntityRecordsForTable", "getAllEntities"]),
    ...mapGetters("companyTemplates", [
      "getSingleCompanyTemplate",
      "getAllCompanyTemplatesData",
      "getAllCompanyTemplates",
    ]),
    ...mapGetters("filters", [
      "getAllEntityFilters",
      "getCreateNewFilter",
      "getUpdateFilterStatus",
      "getDeleteFilterStatus",
      "getCreateNewFilterError",
      "getUpdateFilterError",
      "getDeleteFilterErros",
    ]),
  },
  methods: {
    cancelNewRule() {
      this.resetCurrentTable();
      this.addOrEditingTables = false;
    },
    checkSaveDisabled() {
      if (!this.currentTable.name) {
        return true;
      }
      if (
        this.current_type == "dataTable" &&
        (!this.currentTable.data_table_key ||
          !this.currentTable.data_table_content)
      ) {
        return true;
      } else if (
        this.currentTable.typee == "relationalTable" &&
        (!this.currentTable.entity_id ||
          (!this.currentTable.typee == "dataTable" &&
            this.currentTable.type == "email_body" &&
            !this.currentTable.selectedFields?.length))
      ) {
        return true;
      }
      return false;
    },
    handleDataTableChange(key, isEdit = false) {
      this.getDataTableMentionableFields(key);
      if (!isEdit) this.currentTable.data_table_content = "";
    },
    getNeccessaryDetails() {
      this.tableData = [...this.data];
      this.fieldsObject = {};
      this.dataTableFields = [];
      (this.fields || []).map((e) => {
        if (e.inputType == "DATA_TABLE" || e.input_type == "DATA_TABLE") {
          this.dataTableFields.push(e);
        }
        this.fieldsObject[e.template_key] = e;
      });
      this.editorConfig.mentions = [
        {
          feed: this.dataFeed,
          itemTemplate:
            '<li data-id="{id}">' +
            '<strong class="label">{label}</strong>' +
            "</li>",
          outputTemplate: (item) => {
            if (item.input_type == "IMAGE" || item.inputType == "IMAGE") {
              return `<img alt="${item.label}" 
                            src="https://app-esigns-io.s3.amazonaws.com/ck_editor_assets/MicrosoftTeams-image_8-1716639572596.png?field=[[${item.label}]]" 
                            style="width: 80px;"
                            title="${item.label}" />`;
            } else {
              return `<span>&nbsp;</span><span>[[${item.label}]]</span><span>&nbsp;</span>`;
            }
          },
          minChars: 0,
          convertTo: function (item) {
            return {
              template_key: item.label,
            };
          },
        },
        {
          feed: this.companyDataFeed,
          marker: "#",
          itemTemplate:
            '<li data-id="{id}">' +
            '<strong class="label">{label}</strong>' +
            "</li>",
          outputTemplate:
            "<span>&nbsp;</span><strong>(({id}))</strong><span>&nbsp;</span>",
          minChars: 0,
        },
      ];
      this.editorConfig["id"] = "emailRelationalDataTableConfig";
    },
    addNewRow(type) {
      this.resetCurrentTable();
      this.currentTable["typee"] = type;
      this.currentTableIndex = -1;
      this.addOrEditingTables = true;
      this.current_type = type;
    },
    async editRule(index) {
      this.loadingText = "Fetching data...";
      this.addOrEditingTables = true;
      this.editLoading = true;
      let dt = JSON.parse(JSON.stringify(this.tableData[index]));
      if (!dt.typee) {
        this.currentTable.typee = "relationalTable";
        dt.typee = "relationalTable";
      } else {
        this.currentTable.typee = dt.typee;
      }

      this.current_type = dt.typee;
      this.currentTable.name = dt.name;
      if (this.currentTable.typee == "dataTable") {
        this.currentTable.data_table_key = dt.data_table_key;
        this.currentTable.data_table_content = dt.data_table_content;
        this.handleDataTableChange(dt.data_table_key, true);
      } else {
        await this.getEntityFilters(dt.entity_id);
        this.currentTable.filters = dt.filters;
        this.currentTable.selectedFields = [...dt.selectedFields];
        this.currentTable.entity_id = dt.entity_id;
        this.currentTable.type = dt.type;
      }
      this.currentTableIndex = index;
      this.editLoading = false;
    },
    onDelete(index) {
      this.$confirm("Are you sure to delete the Table?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.deleteRule(index);
      });
    },
    deleteRule(index) {
      this.addOrEditingTables = false;
      this.tableData.splice(index, 1);

      if (!this.tableData?.length) {
        this.resetCurrentTable();
      }
    },

    checkRuleName(event) {
      let ruleNameCheck = this.tableData.filter(
        (el) => el && el.name === event
      );
      if (ruleNameCheck && ruleNameCheck?.length) {
        this.$message.error("Name already exists!");
        this.currentTable.name = "";
      } else if (/^\s*$/.test(event)) {
        this.$message.error("name should not be empty or contain only spaces!");
        this.currentTable.name = "";
      }
    },
    getDataTableMentionableFields(key) {
      this.mentionableDataTableFields = [];
      let selectedDataTable = this.fieldsObject[key];
      if (selectedDataTable?.data_table_columns?.length) {
        this.mentionableDataTableFields =
          selectedDataTable.data_table_columns.map((e) => {
            e.id = e.key;
            return e;
          });
      }
    },
    configureDataTable() {
      const regex = /\[\[(.*?)\]\]/g;
      this.getDataTableMentionableFields(this.currentTable.data_table_key);
      if (this.currentTable.data_table_content)
        this.currentTable.data_table_content = this.parseEmailTemplate(
          regex,
          this.currentTable.data_table_content,
          this.mentionableDataTableFields,
          true
        );
      this.editorKey = this.editorKey + 1;
      this.ckEditorDialog = true;
    },
    resetPopUp() {
      this.ckEditorDialog = false;
      const regex = /\[\[(.*?)\]\]/g;
      if (this.currentTable.data_table_content) {
        this.currentTable.data_table_content = this.parseEmailTemplate(
          regex,
          this.currentTable.data_table_content,
          this.mentionableDataTableFields
        );
      }
    },
    dataFeed(opts, callback) {
      var matchProperty = "label",
        data = this.mentionableDataTableFields.filter(function (item) {
          return (
            item[matchProperty]
              .toLowerCase()
              .indexOf(opts.query.toLowerCase()) !== -1
          );
        });
      data = data.sort(function (a, b) {
        return a[matchProperty].localeCompare(b[matchProperty], undefined, {
          sensitivity: "accent",
        });
      });
      callback(data);
    },
    companyDataFeed(opts, callback) {
      var matchProperty = "label",
        data = this.allCompanyDataFields.filter(function (item) {
          return (
            item[matchProperty]
              .toLowerCase()
              .indexOf(opts.query.toLowerCase()) !== -1
          );
        });
      data = data.sort(function (a, b) {
        return a[matchProperty].localeCompare(b[matchProperty], undefined, {
          sensitivity: "accent",
        });
      });
      callback(data);
    },
    saveNewTable() {
      this.addOrEditingTables = false;
      this.currentTable = {
        ...this.currentTable,
        typee: this.current_type,
      };
      if (this.currentTableIndex == -1) {
        this.tableData.push(this.currentTable);
      } else {
        this.tableData[this.currentTableIndex] = { ...this.currentTable };
      }
    },
    resetCurrentTable() {
      this.currentTable = {
        name: "",
        entity_id: "",
        filters: "",
        selectedFields: [],
        type: "email_body",
        content: "",
      };
      this.currentEntityFilters = [];
      this.selectedEntityFields = [];
    },
    async getEntityFilters(entity_id) {
      this.loadingText = "Fetching filters";
      this.filterLoading = true;
      this.selectedEntity = this.allEntitiesData.find(
        (e) => e._id == entity_id
      );
      this.currentTable["filters"] = "";
      this.currentTable["type"] = "email_body";
      this.currentTable["selectedFields"] = [];
      if (this.currentTable.document_id) {
        this.currentTable["document_id"] = "";
      }
      await this.$store.dispatch("filters/fetchAllEntityFilters", {
        entityId: entity_id,
      });
      this.currentEntityFilters = [];
      if (this.getAllEntityFilters) {
        this.currentEntityFilters = this.getAllEntityFilters;
      }
      this.currentTable["selectedFields"] = [];
      this.selectedEntityFields = await this.fetchEntityDetails(
        entity_id,
        true,
        true
      );
      this.selectedEntityFields = this.selectedEntityFields.filter(
        (e) =>
          e.properties?.filed_content != "Hide" &&
          e.input_type != "DATA_TABLE" &&
          (e.type != "CONTENT" ||
            (e.type == "CONTENT" && e.inputType == "IMAGE"))
      );
      this.filterLoading = false;
    },
    saveAllTables() {
      this.$emit("save-all-tables", this.tableData);
    },
    checkIsDisabled() {
      return this.currentTableIndex == -1 ? false : true;
    },
    handleTypeChange() {
      if (this.currentTable.type == "attachment_as_pdf") {
        this.currentTable["document_id"] =
          this.selectedEntity.export_printing_document;
        this.currentTable.selectedFields = [];
      }
    },
    isPdfOptionDisabled(type) {
      if (
        type == "attachment_as_pdf" &&
        !this.selectedEntity?.export_printing_document
      ) {
        return true;
      }
      return false;
    },
  },

  //   created() {
  //     bus.$on("form-rule-dialog-closed", (data) => {
  //       if (data) {
  //         if (this.tableData.length) {
  //           //do action when template rules exist
  //         } else {
  //           this.addOrEditingTables = false;
  //           this.currentTable = {
  //             name: "",
  //             entity_id: "",
  //             filters: "",
  //             selectedFields: [],
  //             type : "email_body"
  //           };
  //         }
  //       }
  //     });
  //   },
};
</script>

<style lang="scss" scoped>
.action-links {
  float: right !important;
}
.small-input {
  width: 180px;
}

.rule-collapse {
  border: 1px solid #e4e7ed;
  border-radius: 3px;
}

.empty-alert {
  text-align: center !important;

  img {
    width: 100px;
    margin-bottom: 10px;
  }

  .add-rule {
  }
}

.rule-row {
  display: flex !important;
  background: #f2f6fc;
  height: 55px;

  .rule-inner-row {
    display: grid;
    margin: auto;
    margin-right: 25px;
    margin-left: 25px;
    grid-template-columns: auto auto auto auto;
  }
}

.action-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.rule-preview {
  padding: 10px;
  font-size: 12px;

  img {
    width: 25px;
  }
}

.el-date {
}
</style>
